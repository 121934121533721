import React, { useState, useEffect, useCallback, useRef } from 'react';
import IDPage, { ProfileData } from "../components/id/IdPage";
import { getEndpointUrl, getProfileUrl } from '../config/api.config';

const ID: React.FC = () => {
    const [userReference, setUserReference] = useState<string>('Waiting for new transaction...');
    const [timeLeft, setTimeLeft] = useState<number | null>(null);
    const [profileData, setProfileData] = useState<ProfileData | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [connectionAttempts, setConnectionAttempts] = useState<number>(0);
    const [connectionStatus, setConnectionStatus] = useState<'connecting' | 'connected' | 'disconnected'>('connecting');

    // Keep track of the last processed reference to avoid duplicate loading
    const lastProcessedRef = useRef<string | null>(null);

    // Manual layout control
    const showLayout = false;
    const mockUserReference = 'ab680451-4a0b-4afd-be74-cb41ae5162fa';
    const mockTimeLeft = 30;

    const DEFAULT_MESSAGE = 'Waiting for new transaction...';
    const MAX_RECONNECT_ATTEMPTS = 5;
    const RECONNECT_DELAY = 3000; // 3 seconds

    // Function to load profile data
    const loadProfileData = async (reference: string) => {
        if (reference === DEFAULT_MESSAGE) {
            setProfileData(null);
            setError(null);
            setLoading(false);
            return;
        }

        try {
            setLoading(true);
            setError(null);

            const profileUrl = getProfileUrl(reference);
            console.log('Loading profile from:', profileUrl);

            const response = await fetch(profileUrl, {
                // Add timeout to prevent hanging requests
                signal: AbortSignal.timeout(10000) // 10 second timeout
            });

            // Check content type
            const contentType = response.headers.get('content-type');
            if (!response.ok || !contentType?.includes('application/json')) {
                console.error('Profile load failed:', {
                    status: response.status,
                    contentType,
                    text: await response.text() // Log the actual response for debugging
                });
                throw new Error('Profile not found or invalid response');
            }

            const data = await response.json();
            console.log('Profile loaded:', data);
            setProfileData(data);
        } catch (err) {
            console.error('Error loading profile:', err);
            setError(err instanceof Error ? err.message : 'Unable to load profile data');
            setProfileData(null);
        } finally {
            setLoading(false);
        }
    };

    // Create a function to establish SSE connection with proper reconnection logic
    const establishSSEConnection = useCallback(() => {
        if (showLayout) return null; // Don't establish connection in layout mode

        console.log(`Establishing SSE connection (attempt ${connectionAttempts + 1})`);

        const eventSource = new EventSource(getEndpointUrl('ID_EVENTS'), {
            withCredentials: false
        });

        let timeoutId: ReturnType<typeof setTimeout> | null = null;
        let intervalId: ReturnType<typeof setInterval> | null = null;

        // We'll use a ping mechanism instead of auto-reconnect
        // This way, we only reconnect when we're sure the connection is dead
        let connectionActive = true;

        const startTimer = () => {
            setTimeLeft(30);

            // Clear any existing timers
            if (intervalId) clearInterval(intervalId);
            if (timeoutId) clearTimeout(timeoutId);

            // Start countdown
            intervalId = setInterval(() => {
                setTimeLeft(prev => prev !== null ? prev - 1 : null);
            }, 1000);

            // Reset after 30 seconds
            timeoutId = setTimeout(() => {
                setUserReference(DEFAULT_MESSAGE);
                setTimeLeft(null);
                setProfileData(null);
                lastProcessedRef.current = null; // Reset the reference tracking to allow the same user to trigger again
                if (intervalId) clearInterval(intervalId);
            }, 30000);
        };

        eventSource.onopen = () => {
            console.log('SSE connection established successfully');
            setConnectionAttempts(0); // Reset connection attempts on successful connection
            setConnectionStatus('connected');
            connectionActive = true;
        };

        eventSource.onmessage = (event) => {
            // We received a message, so the connection is working
            if (!connectionActive) {
                console.log('Connection restored, received message');
                connectionActive = true;
            }

            try {
                const data = JSON.parse(event.data);

                // Skip connection messages
                if (data.connected !== undefined) {
                    return;
                }

                // Handle cardholderReference update - only if it's a new reference
                if (data.cardholderReference && data.cardholderReference !== lastProcessedRef.current) {
                    console.log(`Processing new reference: ${data.cardholderReference}`);
                    lastProcessedRef.current = data.cardholderReference;
                    setUserReference(data.cardholderReference);
                    loadProfileData(data.cardholderReference);
                    startTimer();
                } else if (data.cardholderReference) {
                    console.log(`Ignoring duplicate reference: ${data.cardholderReference}`);
                }

            } catch (error) {
                console.error('Error processing event:', error);
            }
        };

        eventSource.onerror = (error) => {
            // Only attempt reconnection if the connection was previously active
            // This prevents multiple reconnection attempts
            if (connectionActive) {
                console.error('SSE Connection Error:', error);
                connectionActive = false;
                setConnectionStatus('disconnected');

                // Don't close the connection immediately - the browser will attempt to reconnect automatically
                // Only do manual reconnection if that fails after a delay
                setTimeout(() => {
                    if (!connectionActive) {
                        console.log('Connection still inactive after delay, manual reconnection');
                        cleanupConnection();
                        reconnect();
                    }
                }, 5000); // Wait 5 seconds before attempting manual reconnection
            }
        };

        // Cleanup function to properly close all resources
        const cleanupConnection = () => {
            console.log('Cleaning up SSE connection');
            eventSource.close();
            if (intervalId) clearInterval(intervalId);
            if (timeoutId) clearTimeout(timeoutId);
            connectionActive = false;
        };

        // Function to handle reconnection with backoff
        const reconnect = () => {
            if (connectionAttempts < MAX_RECONNECT_ATTEMPTS) {
                const delay = RECONNECT_DELAY * Math.pow(1.5, connectionAttempts);
                console.log(`Reconnecting in ${delay}ms (attempt ${connectionAttempts + 1} of ${MAX_RECONNECT_ATTEMPTS})`);

                setConnectionAttempts(prev => prev + 1);
                setConnectionStatus('connecting');

                setTimeout(() => {
                    establishSSEConnection();
                }, delay);
            } else {
                console.error('Maximum reconnection attempts reached');
                setError('Unable to maintain connection to server. Please refresh the page.');
            }
        };

        // Return cleanup function for useEffect
        return cleanupConnection;
    }, [connectionAttempts, showLayout]);

    useEffect(() => {
        let cleanup: (() => void) | null = null;

        if (!showLayout) {
            cleanup = establishSSEConnection();
        } else {
            // Handle mock data
            loadProfileData(mockUserReference);
        }

        // Cleanup function
        return () => {
            if (cleanup) cleanup();
        };
    }, [showLayout, establishSSEConnection]);

    return (
        <div className="content-container">
            <IDPage
                cardholderReference={showLayout ? mockUserReference : userReference}
                timeLeft={showLayout ? mockTimeLeft : timeLeft}
                loading={loading}
                error={error}
                profileData={profileData}
            />
        </div>
    );
};

export default ID;